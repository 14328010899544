import React, { useEffect, useState } from 'react';
import {Dropdown, Row, Col, Table, Input, Select, Badge, Button, Avatar, Modal} from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons';
import { IoAdd } from "react-icons/io5";
import moment from "moment";
import {usePrestationFetchMutation} from "../../../planning/services/prestations-api";
import {
    API_ROOT, convertDateStringToTimeString,
    convertDateStringToTimeStringComplete,
    convertDateStringToTimeStringDateOnly,
    monthList
} from "../../../../utils/global-var";
import PlanningDetail from "../../../planning/planning_detail";

const PlanningFilteredPage = ({status, handleCancel, userId, statusPrestation, userInfo}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [prestationGet] = usePrestationFetchMutation();
    const [prestationList, setPrestationList] = useState([]);
    const [prestationId, setPrestationId] = useState(null);
    const [error, setError] = useState(false);
    const [showModalProgrammeApercu, setShowModalProgrammeApercu] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    useEffect(() => {
        handleGet({});
    }, [statusPrestation, userId]);
    console.log(prestationList);
    const handleGet = async (value) => {
        const data = ({ ...value, status: [statusPrestation], type_intervenant: "CUSTOMER", intervenants: [userId] })
        setIsLoading(true);
        prestationGet(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setPrestationList(res);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }

    const columns = [
        {
            title: 'Logement',
            key: "logement",
            render: (record) => <div style={{ cursor: "pointer" }} onClick={() => {setPrestationId(record.id); setShowModalProgrammeApercu(true)}}>
                <div className="titleTable color-213856 sans-pro-regular">{record.logement.libelle}</div>
                <div className="subtitleTable sans-pro-regular color-A7A7A7">{record.logement.ville}</div>
            </div>,
        },
        {
            title: 'Service',
            key: "service",
            render: (record) => <div style={{ cursor: "pointer" }} onClick={() => {setPrestationId(record.id); setShowModalProgrammeApercu(true)}}>
                <div className="titleTable color-213856 sans-pro-regular">{record.service}</div>
            </div>,
        },
        {
            title: 'Date de prestation',
            key: "prestation",
            render: (record) => <div  style={{ cursor: "pointer" }}>
                <div className="titleTable color-213856 sans-pro-regular">{convertDateStringToTimeStringDateOnly(record.creneau_intervention_debut)}</div>
            </div>,
        },
        {
            title: 'créneau d\'intervantion',
            key: "intervenant",
            render: (record) => <>
                <div className="subtitleTable sans-pro-regular color-A7A7A7"> Entre {convertDateStringToTimeString(record?.creneau_intervention_debut)} et {convertDateStringToTimeString(record?.creneau_intervention_fin)}</div>
            </>,
        },
        {
            title: 'statut',
            key: "statut",
            render: (record) => <div className="badgeTable">
                {(record.status === "En cours") && (<Badge color="#faad14" count={"En cours"} />)}
                {(record.status === "Programmé" || record.status === "suspended") && (<Badge color="black" count={"A faire"} />)}
                {(record.status === "Terminé") && (<Badge color={"rgb(0,192,112)"} count={"Terminé"} />)}
                {(record.status === "Annulé") && (<Badge color="red" count={"Annulé"} />)}
            </div>,
        },
        {
            title: 'Forfait',
            key: "forfait",
            render: (record) => <div className="">
                <div className="subtitleTable sans-pro-regular color-A7A7A7">{record.cout_total} €</div>
            </div>,
        },
        {
            title: 'Crée le',
            key: "date",
            render: (record) => <div className="">
                <div className="subtitleTable sans-pro-regular color-A7A7A7">{convertDateStringToTimeStringComplete(record.creneau_intervention_debut)}</div>
            </div>,
        }
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

 console.log(prestationList?.data);
    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:160}}
                width={1400}
                onCancel={handleCancel}
                className="document-client-modal"
            >
        <div className='planning-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Planning de ménage</h1>
                </div>
            </div>

            <div>
                <Row>
                    <Col lg={4}>
                        <Input
                            className='search-input'
                            placeholder="Chercher par nom de logement..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => handleGet(e.target.value === "" ? {} : {search_logement_libelle: e.target.value})}
                        />
                    </Col>
                </Row>
                <br />
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Client:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                disabled={true}
                                suffixIcon={
                                    <div className="icon-logement-select">
                                        <img src="../assets/img/arrow-down-gray.png" alt="dropdown arrow" />
                                    </div>
                                }
                                options={[
                                    { value: 'Tout', label: userInfo.user_firstname+" "+userInfo.user_lastname },
                                ]}
                                popupClassName="status-classe-select-responsable-in-modal"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Statut:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                disabled={true}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    { value: 'Tout', label: statusPrestation },
                                ]}
                                popupClassName="status-classe-select-clinet-ville-in-modal"
                            />
                        </div>
                        {/* <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Ville:</span>
                            <Select
                            defaultValue="Tout"
                            onChange={null}
                        suffixIcon={<div className='icon-logement-select'><img
                            src="../assets/img/arrow-down-gray.png" alt="add"/></div>}
                        options={[
                            {
                                value: 'jack',
                                label: 'Jack',
                            },
                            {
                                value: 'Tout',
                                label: 'Tout',
                            },
                            {
                                value: 'Yiminghe',
                                label: 'yiminghe',
                            },
                            {
                                value: 'disabled',
                                label: 'Disabled',
                            },
                        ]}
                    />
                </div> */}
                    </div>
                </div>
                <br />
                <Row>
                    <Col lg={24} className="">
                        <div className="">
                            <Table
                                className="logement-table"
                                dataSource={prestationList?.data}
                                columns={columns}
                                rowSelection={rowSelection}
                                pagination={{ pageSize: 7 }}
                                rowKey={(record) => record.key}
                                loading={isLoading}
                                locale={{
                                    emptyText: (
                                        <Row>
                                            <Col lg={6}></Col>
                                            <Col lg={12}>
                                                <div className="empty-Tab-planning-prestation">
                                                    <img src="/assets/img/empty-tab.png" alt="airbnb" className="plateforme-img" />
                                                    <div className="title">Aucune prestation prévue ce jour </div>
                                                </div>
                                            </Col>
                                            <Col lg={6}></Col>
                                        </Row>
                                    ),
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
            </Modal>
            {userId !== null && <PlanningDetail
                prestationId={prestationId}
                status={showModalProgrammeApercu}
                userId={userId}
                handleCancel={() => setShowModalProgrammeApercu(false)}
            />}
            </>
    );
}

export default PlanningFilteredPage;
