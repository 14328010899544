import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {Dropdown, Row, Col, Button, Table, Input, Select, Menu, Avatar, Popover, Progress, Drawer, Modal} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FaArrowRightLong } from "react-icons/fa6";
import {IoAdd, IoChevronBackOutline, IoChevronForwardOutline, IoEllipsisHorizontal} from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import "moment/locale/fr";
//import "./styles/style.scss"
import 'react-circular-progressbar/dist/styles.css';
import ValidProgrammationModalView from "../../../planning/modal/valid_programmation";
import ProgrammerManuellementModalView from "../../../planning/modal/programmer-manuellement";
import DemanderEquipeLogementModalView from "../../../logements/modal/demander-equipe";
import ImpossibleSupprimerLogementModalView from "../../../logements/delete/imposible-suprimer";
import SupprimerLogementModalView from "../../../logements/delete/supprimer";
import {setRefresh} from "../../../logements/services/logement-slice";
import {renameLogement} from "../../../logements/utils/logement-utils";
import {API_ROOT, convertDateStringToTimeStringComplete} from "../../../../utils/global-var";
import {
    useLogementCreateMutation,
    useLogementFetchOne2Mutation,
    useLogementWithPerformanceMutation
} from "../../../logements/services/logement-api";

const LogementFilteredPage = ({status, handleCancel, userId, statusLogement, userInfo, closeDrawer, setOpenLogement, setRecordLogement}) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalSupprimer, setShowModalSupprimer] = useState(false);
    const [showModalDemande, setShowModalDemande] = useState(false);
    const [showModalImpossibleSupprimer, setShowModalImpossibleSupprimer] = useState(false);
    const [showModalProgrammerService, setShowModalProgrammerService] = useState(false);
    const [showModalProgrammerValid, setShowModalProgrammerValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLogements, setIsLoadingLogements] = useState(false);
    const [logementId, setLogementId] = useState();
    const [logements, setLogements] = useState([]);
    const [responsables, setResponsables] = useState([]);
    const [planningStatus, setPlanningStatus] = useState([]);
    const [villes, setVilles] = useState([]);
    const [statusColors, setStatusColors] = useState([]);
    const [error, setError] = useState(false);
    const [logementCreate] = useLogementCreateMutation();
    const [logementWithPerformance] = useLogementWithPerformanceMutation();
    const [logementFetchOne2] = useLogementFetchOne2Mutation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { isRefresh } = useSelector((state) => state.logement);
    const dispatch = useDispatch();
    const [recorded, setRecord] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState([]);
    console.log(logements);
    useEffect(() => {
        handleFetchLogements({ page: page, pageSize: pageSize, order: "DESC", search: search });
    }, [page,pageSize, search, statusLogement, userId ]);

    useEffect(() => {
        setPage(1);
    }, [statusLogement ]);

    const getMenuItems = (record) => [
        {
            label:
                <div className="add-logement2-item sans-pro-regular" onClick={() => {  setSelectedRowKeys([record.logement_id]); setShowModalDemande(true); }}>
                    <div className="imageSide">
                        <img src="../assets/img/equipe-add.png" alt="add" />
                    </div>
                    Demander une équipe de ménage
                </div>,
            key: '1itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular">
                <div className="imageSide">
                    <img src="../assets/img/switch-team.png" alt="add" />
                </div>
                Attribuer un responsable de site
            </div>,
            key: '2itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular" onClick={() => setShowModalProgrammerService(true)}>
                <div className="imageSide reduction-taille">
                    <img src="../assets/img/presta-icon.png" alt="add" />
                </div>
                Programmer une prestation
            </div>,
            key: '3itemsOption',
        },
        {
            type: 'divider',
        },
        {
            label:
                <div className="add-logement2-item sans-pro-regular" style={{ color: "red" }} onClick={() => { setLogementId(record.logement_id); setShowModalSupprimer(true) }}>
                    <div className="imageSide">
                        <img src="../assets/img/trash.png" alt="add" />
                    </div>
                    Supprimer le logement
                </div>,
            key: '6itemsOption',
        },
    ];

    const customStatus = (status) => {
        switch (status) {
            case "ORANGE":
                return "#F5C242";
            case "GREEN":
                return "#65ae04";
            case "RED":
                return "#ff0000e3";
            default:
                return "gray";
        }
    };
    const contentInfo = (
        <div className="infoLogement">
            <p>
                Code couleur : <br />
                Rond vert = Logement complété à 100% et <br /> équipe de ménage en place <br />
                Rond orange = Logement complété à 100% <br /> sans équipe de ménage <br />
                Rond rouge = Logement à compléter (tous <br /> les champs obligatoires ne sont pas remplis
            </p>
        </div>
    );
    const contentInfo2 = (
        <div className="infoLogement">
            <p>
                Ce paramètre indique le taux de <br />
                réussite des prestations sur le <br />
                logement, soit le pourcentage de <br />
                prestations qui ont reçu une note de 4 <br />
                ou 5 étoiles sur l’ensemble des <br />
                prestations effectuées.
            </p>
        </div>
    );

    const columns = [
        {
            title: "Nom du logement",
            width: "20%",
            render: (record) => (
                <div style={{ cursor: 'pointer', color: "#263754", fontSize: "15px" }} onClick={() => {setRecordLogement(record); handleCancel(); closeDrawer(); setOpenLogement(true);}}>
                    <span>{record.logement_libelle}</span>
                </div>
            ),
        },

        {
            title: '',
            render: (record) => (
                <div>{
                    record.logement_gestion_cle === "Jeu de clés confié à l’aide-ménagère" && <img src="./assets/img/icon-keys.png" className="icon-sidebar" style={{ width: "14px", marginRight: "" }} />}
                </div>
            ),
        },

        {
            title: <div className="statusHeadColClass">
                Statut
                <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                    <div className="info-logement">
                        <img src="/assets/img/info-circled.png" alt="info" />
                    </div>
                </Popover>
            </div>,
            render: (record) => (
                <div>
                    <GoDotFill className='icon' style={{ color: `${customStatus(record.status_color)}`, fontSize: "25" }} />
                    {record.icalendar_url_number !== "0" ? <img src="../assets/img/recycle.png" alt="add" style={{ width: "16px", marginLeft: "5px" }} /> : ""}
                </div>
            ),
        },

        {
            title: 'Responsable de site',
            render: (record) => (
                <div>
                    <Avatar src={`${API_ROOT}/${record.responsable_avatar}`} />
                </div>
            ),
        },
        {
            title: 'Prochaine prestation',
            render: (record) => (
                <div>
                    <span>
                        {record.next_creneau_intervention_debut !== null ? convertDateStringToTimeStringComplete(record.next_creneau_intervention_debut) : "Non définit"}</span>
                    <span>
                        {record.prestations_total_number === "1" ? <img src="../assets/img/etoile.webp" alt="add" style={{ width: "21px", marginLeft: "5px", position: 'absolute', marginTop: '-1px' }} /> : ""}
                    </span>
                </div>
            ),
        },
        {
            title: 'Forfait ménage',
            render: (record) => (
                <div>{record.forfait_menage !== null ? record.forfait_menage+" €" : "Non définit"} </div>
            ),
        },
        {
            title: 'Promo',
            width: "7%",
            render: (record) => (
                <div>
                    {record.on_promotion === 1 ? <div className='tarif-reduit'>Tarif reduit</div> : ""}
                </div>
            ),
        },
        {
            title: <div className="statusHeadColClass">
                Perf
                <Popover placement="top" content={contentInfo2} overlayClassName="infoContentLogement">
                    <div className="info-logement">
                        <img src="/assets/img/info-circled.png" alt="info" />
                    </div>
                </Popover>
            </div>,
            render: (record) => (
                <div>
                    <Progress type="circle" percent={record.performance_percentage} size={20} />
                </div>
            ),
        },
        {
            title: "Créé le",
            key: "createdAt",
            render: (record) => (
                <span> {convertDateStringToTimeStringComplete(record.logement_createdAt)}</span>
            ),
        },
        {
            title: "",
            key: "option",
            width: 5,
            render: (record) => (
                <div className="tab-option">
                    <Dropdown
                        overlay={<Menu items={getMenuItems(record)} />}
                        placement="bottomRight" trigger={['click']}>
                        <IoEllipsisHorizontal style={{ fontSize: "19px", marginRight: "10px" }} />
                    </Dropdown>
                </div>
            ),
        },
    ];


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 1;

    const onMenuClick = (e) => {
        console.log('click', e);
    };

    const items2 = [
        {
            key: '1items2',
            label: '1st item',
        },
        {
            key: '2items2',
            label: '2nd item',
        },
        {
            key: '3items2',
            label: '3rd item',
        },
    ];

    const items = [
        {
            label: <div className="add-logement-item add-logement-item-1 sans-pro-regular" onClick={() => handleCreate()}>
                <div className="imageSide">
                    <img src="../assets/img/ajouter-logement.png" alt="add" />
                </div>
                Ajouter un logement
            </div>,
            key: '1items',
        },
        {
            label: <div className="add-logement-item add-logement-item-2 disable">
                <div className="imageSide">
                    <img src="../assets/img/importer-masse.png" alt="add" />
                </div>
                Importer en masse
            </div>,
            key: '2items',
        },
    ];

    let locale = {
        emptyText: (
            <div className='empty-blc'>
                <div className='blc'>
                    <div className='home-icon'>
                        <img src='assets/img/icon-logement.png' />
                    </div>
                    <h4>Créez votre premier logement</h4>
                    <p>Ajoutez un logement et completez le cahier des charges pour obtenir le forfait ménage</p>
                    <NavLink to={"#"} className="en-savoir-plus">En savoir plus <FaArrowRightLong className='icon' /></NavLink>
                    <Button type="primary" className='ant-btn-icon' onClick={() => handleCreate()} loading={isLoading}><IoAdd className='icon' /> Ajouter un logement</Button>
                </div>
            </div>
        )
    };


    const handleFetchLogements = async (data) => {
        setIsLoadingLogements(true)
        let localdata = {...data, status_color: [statusLogement], users: [userId]  }
        logementWithPerformance(localdata).unwrap()
            .then((res) => {
                setResponsables(res.filters.responsables)
                setStatusColors(res.filters.status_color)
                setPlanningStatus(res.filters.planning_status)
                setVilles(res.filters.villes)
                dispatch(setRefresh(false));
                setLogements(renameLogement(res.data.logements));
                setPagination(res.data.pages);
                setIsLoadingLogements(false)
            })
            .catch(() => {
                setIsLoadingLogements(false)
            });
    };


    // CREATE
    const handleCreate = async () => {
        const data = ({ libelle: "Logement de " + user.firstname, user: user.id })
        setIsLoading(true);
        logementCreate(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setShowModal(true)
                setLogementId(res.id);
                dispatch(setRefresh(true));
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }


    // DUPLICATE
    const handleDuplicate = async (id) => {
        setIsLoadingLogements(true);
        logementFetchOne2({ id: id })
            .unwrap()
            .then((res) => {
                const { id, cart, assignment, cout_duree, demande_specifique, icalendarUrl, instructions, kit, prestation, user, createdAt, updtedAt, ...newData } = res;
                const data = { ...newData, user: user.id }
                logementCreate(data)
                    .unwrap()
                    .then(() => {
                        dispatch(setRefresh(true));
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error.data.message);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }

    const handleDuplicateMultiple = async () => {
        setIsLoadingLogements(true);
        const duplicatePromises = [];
        for (const id of selectedRowKeys) {
            const duplicatePromise = logementFetchOne2({ id: id })
                .unwrap()
                .then((res) => {
                    const { id, cart, assignment, cout_duree, demande_specifique, icalendarUrl, instructions, kit, prestation, user, createdAt, updatedAt, ...newData } = res;
                    const data = { ...newData, user: user.id };
                    return logementCreate(data).unwrap();
                })
                .catch((error) => {
                    console.error(`Failed to duplicate logement with ID ${id}`);
                    throw error;
                });

            duplicatePromises.push(duplicatePromise);
        }

        Promise.all(duplicatePromises)
            .then(() => {
                dispatch(setRefresh(true));
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }


    // FILTERS
    const handleFilterStatusColor = (value) => {
        handleFetchLogements({ status_planning: value })
    };

    const handleFilterResponsable = (value) => {
        if (value === "Tout") {
            handleFetchLogements({ page: 1, pageSize: 5, order: "DESC" })
        } else {
            handleFetchLogements({ responsables: [value] })
        }
    };

    const handleFilterVilles = (value) => {
        if (value === "Tout") {
            handleFetchLogements({ page: 1, pageSize: 10, order: "DESC" })
        } else {
            handleFetchLogements({ villes: [value] })
        }
    };



    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:160}}
                width={1400}
                onCancel={handleCancel}
                className="document-client-modal"
            >
        <div className='logement-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Logements</h1>
                </div>
            </div>
            {hasSelected && <div className="selectBar">
                <div className="contentBar">
                    <div className="selection"> {selectedRowKeys?.length} selectionnés</div>
                    <div className="divider"></div>
                    <div className="demande-equipe" onClick={() => setShowModalDemande(true)}> <div className="image"><img src="../assets/img/equipe-icon-w.png" alt="add" /></div>    Demander une équipe de ménage</div>
                    <div className="divider"></div>
                    <div className="demande-equipe" onClick={() => handleDuplicateMultiple()} > <div className="image"> <img src="../assets/img/user-empty.png" alt="add" /></div>    Attribuer à un responsable</div>
                    <div className="divider"></div>
                    <div className="demande-equipe" onClick={() => setShowModalSupprimer(true)}> <div className="image"><img src="../assets/img/trash-w.png" alt="add" /></div>    Supprimer</div>
                </div>
            </div>}

            <div>
                <Row>
                    <Col lg={4}>
                        <Input
                            className='search-input'
                            placeholder="Chercher par nom de logement..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e)=> setSearch(e.target.value)}
                        />
                    </Col>
                </Row>
                <br />
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Client:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                disabled={true}
                                suffixIcon={
                                    <div className="icon-logement-select">
                                        <img src="../assets/img/arrow-down-gray.png" alt="dropdown arrow" />
                                    </div>
                                }
                                options={[
                                    { value: 'Tout', label: userInfo.user_firstname+" "+userInfo.user_lastname },
                                ]}
                                popupClassName="status-classe-select-responsable-in-modal"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Statut:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                disabled={true}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    { value: 'Tout', label: statusLogement === "GREEN" ? "Actif" : statusLogement === "RED" ? "Brouillon" : "En attente" },
                                ]}
                                popupClassName="status-classe-select-clinet-ville-in-modal"
                            />
                        </div>
                    </div>
                </div>
                <br />
                <Table
                    className="logement-table"
                    // pagination={{ p#b5bba1: 10 }}
                    rowSelection={rowSelection}
                    dataSource={logements}
                    columns={columns}
                    loading={isLoadingLogements}
                    rowKey={(record) => record.logement_id}
                />
                <div className="pagination-custom">
                    <ul>
                        <li className={"fleche "+(page === 1 ?"desactive" : "")} onClick={page === 1 ? null : ()=>setPage(page-1)}><IoChevronBackOutline /></li>
                        {page > 3 && <li onClick={()=>setPage(1)}>{1}</li>}
                        {page > 4 && <li className="point">...</li>}
                        {page - 2 >0 && <li  onClick={()=>setPage(page-2)}>{page - 2}</li>}
                        {page - 1 >0 && <li  onClick={()=>setPage(page-1)}>{page - 1}</li>}
                        <li className="active" >{page}</li>
                        {page + 1 < pagination?.totalPages && <li  onClick={()=>setPage(page+1)}>{page + 1}</li>}
                        {page + 2 < pagination?.totalPages && <li  onClick={()=>setPage(page+2)}>{page + 2}</li>}
                        {page < pagination?.totalPages && <li className="point">...</li>}
                        {page < pagination?.totalPages && <li  onClick={()=>setPage(pagination?.totalPages)}>{pagination?.totalPages}</li>}
                        <li className={"fleche "+(page === pagination?.totalPages ?"desactive" : "")} onClick={page === pagination?.totalPages ? null : ()=>setPage(page+1)}><IoChevronForwardOutline /></li>
                    </ul>
                </div>
            </div>

            <SupprimerLogementModalView
                logementId={logementId}
                logementIds={selectedRowKeys}
                status={showModalSupprimer}
                nbLogement={selectedRowKeys?.length}
                handleShowNotPossibleModal={setShowModalImpossibleSupprimer}
                handleCancel={() => setShowModalSupprimer(false)}
            />
            <ImpossibleSupprimerLogementModalView
                status={showModalImpossibleSupprimer}
                nbLogement={selectedRowKeys?.length}
                handleCancel={() => setShowModalImpossibleSupprimer(false)}
            />
            <DemanderEquipeLogementModalView
                logementIds={selectedRowKeys}
                status={showModalDemande}
                nbLogement={selectedRowKeys?.length}
                handleCancel={() => setShowModalDemande(false)}
            />
            <ProgrammerManuellementModalView
                status={showModalProgrammerService}
                setValidProgram={setShowModalProgrammerValid}
                handleCancel={() => setShowModalProgrammerService(false)}
            />
            <ValidProgrammationModalView
                status={showModalProgrammerValid}
                handleCancel={() => setShowModalProgrammerValid(false)}
            />
        </div>
            </Modal>
        </>
    );
}

export default LogementFilteredPage;
