import React, {useEffect, useState} from 'react';
import "../styles/style.scss"
import {Avatar, Button, Col, Divider, Drawer, Dropdown, Menu, Popconfirm, Row, Spin, Tag} from "antd";
import {IoEllipsisHorizontal, IoEllipsisVertical, IoTrashOutline} from "react-icons/io5";
import SupprimerLogementModalView from "../delete/supprimer";
import ImpossibleSupprimerLogementModalView from "../delete/imposible-suprimer";
import ModifierForfaitView from "../modal/modifier-forfait";
import KitAccueilModalView from "../../planning/modal/modal/apercu_planning/kit-acceuil";
import DemanderEquipeLogementModalView from "../modal/demander-equipe";
import AssignerEquipeLogementModalView from "../modal/assigner-responsable";
import AssignerAideEquipeLogementModalView from "../modal/assigner-aide";
import {useLogementFetchOneQuery, useLogementStatsFetchOneQuery} from "../services/logement-api";
import {API_ROOT, convertMinutesToHours} from "../../../utils/global-var";
import PlanningFilteredLogementPage from "./modal/prestation-filtered-modal";
import {useStatusColorsFetchQuery} from "../services/logement-color-statut-api";
import SupprimerAssignationModalView from "../delete/supprimer-assignation";
import ClientsDrawerPage from "../../clients/drawer/client-drawer";
import ProgrammerManuellementModalView from "../../planning/modal/programmer-manuellement";
import InstructionLogementPage from "./modal/instruction-access-modal";
import LaundryCautionFeesModal from '../modal/laundry-caution-fees';

const LogementsDrawerPage = ({record, close, isAddPrestation}) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalSupprimer, setShowModalSupprimer] = useState(false);
    const [showCautionFeesModal, setShowCautionFeesModal] = useState(false);
    const [logementId, setLogementId] = useState(null);
    const [statusPrestation, setStatusPrestation] = useState("");
    const [showModalImpossibleSupprimer, setShowModalImpossibleSupprimer] = useState(false);
    const [showModalModifierForfait, setShowModalModifierForfait] = useState(false);
    const [showModalAccueil, setShowModalAccueil] = useState(false);
    const [showModalDemande, setShowModalDemande] = useState(false);
    const [showModalAssigner, setShowModalAssigner] = useState(false);
    const [showModalAssignerAide, setShowModalAssignerAide] = useState(false);
    const [showModalPrestation, setShowModalPrestation] = useState(false);
    const [showModalInstruction, setShowModalshowModalInstruction] = useState(false);
    const [showModalSupprimerAssignation, setShowModalSupprimerAssignation] = useState(false);
    const [showModalProgrammerService, setShowModalProgrammerService] = useState(false);
    const [petitDej, setPetitDej] = useState(0);
    const [entretien, setEntretien] = useState(0);
    const [hygiene, setHygiene] = useState(0);
    const [hygienemini, setHygienemini] = useState(0);
    const [entretienmini, setEntretienmini] = useState(0);
    const [petitDejmini, setPetitDejmini] = useState(0);
    const currentLogement = useLogementFetchOneQuery(record.logement_id);
    const currentLogementStats = useLogementStatsFetchOneQuery(record.logement_id);
    const currentLogementColor = useStatusColorsFetchQuery(record.logement_id);
    const [isLoading, setIsLoading] = useState(false);
    const [service, setService] = useState("classic");
    const [open, setOpen] = useState(false);
    const [recorded, setRecord] = useState(null);
    const [openLogement, setOpenLogement] = useState(false);
    const [recordedLogement, setRecordLogement] = useState(null);

    useEffect(() => {
        if (currentLogement.data?.length !== 0 && currentLogement?.data !== null) {
            setPetitDej(0);
            setEntretien(0);
            setHygiene(0);
            setEntretienmini(0);
            setHygienemini(0);
            setPetitDejmini(0);
            currentLogement?.data?.cart.map((item, index) => {
                if (item.produit.produit === "Kit Petit déjeuner") {
                    setPetitDej(item.quantite);
                }
            });
            currentLogement?.data?.cart.map((item, index) => {
                if (item.produit.produit === "Kit Entretien") {
                    setEntretien(item.quantite);
                }
            });
            currentLogement?.data?.cart.map((item, index) => {
                if (item.produit.produit === "Kit Hygiène") {
                    setHygiene(item.quantite);
                }
            });
            currentLogement?.data?.cart.map((item, index) => {
                if (item.produit.produit === "Mini Kit Petit déjeuner") {
                    setPetitDejmini(item.quantite);
                }
            });
            currentLogement?.data?.cart.map((item, index) => {
                if (item.produit.produit === "Mini Kit Entretien") {
                    setEntretienmini(item.quantite);
                }
            });
            currentLogement?.data?.cart.map((item, index) => {
                if (item.produit.produit === "Mini Kit Hygiène") {
                    setHygienemini(item.quantite);
                }
            });
            if(isAddPrestation){
                setShowModalProgrammerService(true);
            }
        }
    }, [currentLogement, showModalSupprimerAssignation, isAddPrestation]);

    console.log("infos-------->");
    console.log(currentLogement);
    console.log(record);
    console.log(isAddPrestation);
    const getMenuItemsInfo = (record) => {
        const items = [
        {
            label:
                <div className="add-logement3-item sans-pro-regular" onClick={() => {
                    setLogementId(record.logement_id);
                    setShowModalSupprimer(true)
                }}>
                    Supprimer le logement
                </div>,
            key: '1itemsOptionInfo',
        }       
      ];
      if(currentLogement?.data?.laundryManagementType === "FULLY_MANAGED") {
        items.push({
            label:
                <div className="add-logement3-item sans-pro-regular" onClick={() => {
                    setLogementId(record.logement_id);
                    setShowCautionFeesModal(true)
                }}>
                    Gérer la caution linge
                </div>,
            key: '1itemsOptionInfo',
        });
      }

      return items;
   }
    const getMenuItemsService = (record) => [
        {
            label:
                <div className="add-logement3-item sans-pro-regular" onClick={() => {
                    setLogementId(record.logement_id);
                    setShowModalModifierForfait(true)
                }}>
                    Modifier le forfait
                </div>,
            key: '1itemsOptionService',
        },
    ];
    const getMenuItemsKit = (record) => [
        {
            label:
                <div className="add-logement3-item sans-pro-regular" onClick={() => {
                    setLogementId(record.logement_id);
                    setShowModalAccueil(true)
                }}>
                    Modifier le kit d'accueil
                </div>,
            key: '1itemsOptionKit',
        },
    ];

    const getMenuItemsIcal = () => [
        {
            label:
                <div
                    className={"add-logement4-item sans-pro-regular "}
                    onClick={() => {

                    }}>
                    Ajouter un iCal
                </div>,
            key: '1itemsOptionEquipe',
        },
    ];
    const getMenuItemsPrestation = () => [
        {
            label:
                <div
                    className={"add-logement3-item sans-pro-regular "+ (record.status_color !== "GREEN" ? " disable" : "")}
                    onClick={record.status_color !== "GREEN" ? null : () => {
                        setShowModalProgrammerService(true);
                    }}>
                    Programmer une prestation
                </div>,
            key: '1itemsOptionPrestation',
        },
    ];
    const getMenuItemsEquipe = () => [
        {
            label:
                <div
                    className={"add-logement3-item sans-pro-regular " + (record.status_color === "RED" ? " disable" : "")}
                    onClick={record.status_color === "RED" ? null : () => {
                        setLogementId(record.logement_id);
                        setShowModalDemande(true)
                    }}>
                    Demander une équipe
                </div>,
            key: '1itemsOptionEquipe',
        },
        {
            label:
                <div
                    className={"add-logement3-item sans-pro-regular " + (record.status_color === "RED" ? " disable" : "")}
                    onClick={record.status_color === "RED" ? null : () => {
                        setLogementId(record.logement_id);
                        setShowModalAssigner(true)
                    }}>
                    Assigner un responsable
                </div>,
            key: '2itemsOptionEquipe',
        },
        {
            label:
                <div
                    className={"add-logement3-item sans-pro-regular " + (record.status_color === "RED" ? " disable" : "")}
                    onClick={record.status_color === "RED" ? null : () => {
                        setLogementId(record.logement_id);
                        setShowModalSupprimerAssignation(true);
                    }}>
                    Retirer l’assignation
                </div>,
            key: '4itemsOptionEquipe',
        },
        /*{
            label:
                <div className={"add-logement3-item sans-pro-regular "+(record.status_color === "RED" || currentLogement?.data?.assignment?.aidemenagere !== null ? " disable" : "")} onClick={record.status_color === "RED" || currentLogement?.data?.assignment?.aidemenagere !== null ? null : () => { setLogementId(record.logement_id);setShowModalAssignerAide(true)}}>
                    Assigner une aide-ménagère
                </div>,
            key: '3itemsOptionEquipe',
        },*/
    ];

    const gestionLingeChecked = (logement) => {
        switch (logement) {
            case "SELF":
                return "Fourni & lavé par le client";
            case "FULLY_MANAGED":
                return "Fourni & lavé par Kliner";
            case "PARTIALLY_MANAGED":
                return "Fourni par le client + Lavomatic";
            default:
                return "";
        }
    };

    const customStatus = (status) => {
        switch (status) {
            case "ORANGE":
                return "#F5C242";
            case "GREEN":
                return "#65ae04";
            case "RED":
                return "#ff0000e3";
            default:
                return "gray";
        }
    };

    const menuCle = (
        <Menu>
            <Menu.Item key="cle">
                <strong>Gestion des clés</strong>
                <p>
                    {currentLogement?.data?.gestion_cle ?? "Aucunes indications."}
                </p>
            </Menu.Item>
        </Menu>
    );
    const menuAcces = (
        <Menu>
            <Menu.Item key="cle">
                <strong>Instructions d'accès</strong>
                <p>
                    {currentLogement?.data?.instructions.length === 0 && "Aucunes indications."}
                </p>
            </Menu.Item>
        </Menu>
    );
    const menuConsigne = (
        <Menu>
            <Menu.Item key="cle">
                <strong>Consignes de ménage</strong>
                <p>
                    {currentLogement?.data?.demande_specifique.length === 0 ? "Aucunes indications." : "traite"}
                </p>
            </Menu.Item>
        </Menu>
    );

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            {isLoading ? (<Spin/>) :
                <div className="record-logment">
                    <div className="head">
                        <div className="date sans-pro-semi-bold"></div>
                        <div className="type">
                            <div className="icone">
                                <img src="./assets/img/sac.png" alt="icone"/>
                            </div>
                            <div className="text sans-pro-semi-bold">
                                {service === "classic" && <div>{(parseFloat(currentLogement?.data?.cout_duree?.cout_menage_classique) + parseFloat(currentLogement?.data?.cout_duree?.cout_kit_linge) + parseFloat(currentLogement?.data?.cout_duree?.cout_kit_accueil)).toFixed(2)} €</div>}
                                {service === "profondeur" && <div>{(parseFloat(currentLogement?.data?.cout_duree?.cout_menage_profondeur) + parseFloat(currentLogement?.data?.cout_duree?.cout_kit_linge) + parseFloat(currentLogement?.data?.cout_duree?.cout_kit_accueil)).toFixed(2)} €</div>}
                            </div>
                        </div>
                    </div>
                    <div className="photo" style={{cursor: "pointer"}}
                         onClick={() => (setRecord(currentLogement?.data?.user), setOpen(true))}>
                        {
                            currentLogement?.data?.user?.avatar === null ?
                                <Avatar style={{
                                    backgroundColor: "#EDEDED",
                                    color: "black",
                                    fontSize: "35px",
                                    fontWeight: "300"
                                }}> {currentLogement?.data?.user?.firstname.toUpperCase().charAt(0)}{currentLogement?.data?.user?.lastname.toUpperCase().charAt(0)} </Avatar> :
                                <Avatar src={`${API_ROOT}/${currentLogement?.data?.user?.avatar}`}/>
                        }
                        <div className="infication-logement">
                            <img src="./assets/img/Logements icon.png" alt="logement"/>
                        </div>
                    </div>
                    <div className="idLogement">
                        #{currentLogement?.data?.id}
                    </div>
                    <div className="content">
                        <div className="info">
                            <div className="name">
                                <div className="point-vert"
                                     style={{backgroundColor: customStatus(currentLogementColor?.data && currentLogementColor?.data.length > 0 ? currentLogementColor?.data[0].status_color : "")}}></div>
                                <div
                                    className="nom sans-pro-semi-bold">{currentLogementColor?.data && currentLogementColor?.data.length > 0 ? currentLogementColor?.data[0].logement_libelle : ""}</div>
                                <div className="sigle sans-pro-semi-bold">
                                    <div className="etoile">*</div>
                                    {currentLogement?.data?.superficie ?? " 0"} m²
                                </div>
                                <div className="menu">
                                    <Dropdown
                                        overlay={<Menu items={getMenuItemsInfo(record)}/>}
                                        placement="bottomLeft" trigger={['click']}>
                                        <IoEllipsisVertical/>
                                    </Dropdown>
                                </div>
                                <div
                                    className="pays sans-pro-regular">{currentLogement?.data?.adresse}, {currentLogement?.data?.codepostal}, {currentLogement?.data?.ville}, {currentLogement?.data?.pays}</div>
                            </div>
                            <div className="info-supp first">
                                <div className="icone-enveloppe">
                                    <img src="./assets/img/king size icon.png" alt="icon"/>
                                    <div
                                        className="counter sans-pro-semi-bold">{currentLogement?.data?.lit_king ?? 0}</div>
                                </div>
                                <div className="icone-call">
                                    <img src="./assets/img/2bed icon.png" alt="icon"/>
                                    <div
                                        className="counter sans-pro-semi-bold">{currentLogement?.data?.lit_double ?? 0}</div>
                                </div>
                                <div className="icone-call">
                                    <img src="./assets/img/1bed icon.png" alt="icon"/>
                                    <div
                                        className="counter sans-pro-semi-bold">{currentLogement?.data?.lit_simple ?? 0}</div>
                                </div>
                                <div className="icone-call">
                                    <img src="./assets/img/douche icon.png" alt="icon"/>
                                    <div
                                        className="counter sans-pro-semi-bold">{currentLogement?.data?.douche ?? 0}</div>
                                </div>
                            </div>
                            <div className="info-supp">

                                <Dropdown
                                    overlay={menuCle}
                                    placement="topLeft"
                                    arrow
                                >
                                    <div className="icone-enveloppe">
                                        <img src="./assets/img/keys - Modifié (1).png" style={{width: "13px"}}
                                             alt="icon"/>
                                    </div>
                                </Dropdown>

                                <Dropdown
                                    overlay={currentLogement?.data?.instructions.length === 0 ? menuAcces : <div></div>}
                                    placement="top"
                                    arrow
                                >
                                    <div className="button-info-supp">
                                        <Button onClick={currentLogement?.data?.instructions.length === 0 ? null : () => {setShowModalshowModalInstruction(true)}}>
                                            Instruction d'accès
                                        </Button>
                                    </div>
                                </Dropdown>
                                <Dropdown
                                    overlay={menuConsigne}
                                    placement="top"
                                    arrow
                                >
                                    <div className="button-info-supp">
                                        <Button>
                                            Consigne de ménage
                                        </Button>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <Divider/>
                    <div className="content">
                        <div className="service">
                            <div className="title sans-pro-semi-bold">
                                SERVICES
                                <div className="menu">
                                    <Dropdown
                                        overlay={<Menu items={getMenuItemsService(record)}/>}
                                        placement="bottomLeft" trigger={['click']}>
                                        
                                        <IoEllipsisVertical/>
                                    </Dropdown>
                                </div>
                            </div>
                            <br/>
                            {service === "classic" && <div>
                                <Row>
                                    <Col lg={8}>
                                        <div className="section">
                                            Ménage classique
                                        </div>
                                        <div className="data">
                                            {currentLogement?.data?.cout_duree?.cout_menage_classique}€
                                            <div className="image" onClick={() => setService("profondeur")}>
                                                <img src="./assets/img/switch-team.png" alt="icone"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="verticalDivider"></div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="section">
                                            Durée
                                        </div>
                                        <div className="data">
                                            {convertMinutesToHours(currentLogement?.data?.cout_duree?.duree_menage_classique)}
                                        </div>
                                    </Col>
                                    <Col lg={1}>
                                        <div className="verticalDivider"></div>
                                    </Col>
                                    <Col lg={9}>
                                        <div className="section">
                                            Linge &nbsp;
                                            {currentLogement?.data?.laundryManagementType === "FULLY_MANAGED" &&
                                             currentLogement?.data?.caution_fee_status === "paid" &&
                                            <Tag color={`#4e8900`} style={{fontSize: "10px"}}>CAUTION OK</Tag>
                                            }
                                            {currentLogement?.data?.laundryManagementType === "FULLY_MANAGED" &&
                                             (currentLogement?.data?.caution_fee_status === "unpaid" || currentLogement?.data?.caution_fee_status === "paymentFailed") &&
                                            <Tag color={`red`} style={{fontSize: "10px"}}>CAUTION IMPAYÉE</Tag>
                                            }
                                        </div>
                                        <div className="data">
                                            {currentLogement?.data?.cout_duree?.cout_kit_linge}€
                                        </div>
                                    </Col>
                                </Row>
                                <div className="kitmore">
                                    {currentLogement?.data?.statut_kit_linge &&
                                        <div className="kitLinge"><img src="./assets/img/materiel.png" alt="img"/>
                                        </div>}
                                    {currentLogement?.data?.statut_produit_menager &&
                                        <div className="kitProduitMenager"><img src="/assets/img/pdt menager.png"
                                                                                alt="img"/></div>}
                                    {(currentLogement?.data?.statut_kit_linge || currentLogement?.data?.statut_produit_menager) &&
                                        <div
                                            className="kitGlobalValue">({(currentLogement?.data?.statut_kit_linge ? 3 : 0) + (currentLogement?.data?.statut_produit_menager ? 5 : 0)}€)</div>}
                                </div>
                                {currentLogement?.data?.laundryManagementType === "FULLY_MANAGED" && currentLogement?.data?.laundryPlan === "luxury" &&
                                    <div className="diamand">
                                        <img src="../assets/img/Linge Premium.png" alt="dia"/>
                                    </div>}
                                <div className="indication-lavage">
                                    {gestionLingeChecked(currentLogement?.data?.laundryManagementType)}
                                </div>
                            </div>}
                            {service === "profondeur" && <div>
                                <Row>
                                    <Col lg={8}>
                                        <div className="section">
                                            Ménage en profondeur
                                        </div>
                                        <div className="data">
                                            {currentLogement?.data?.cout_duree?.cout_menage_profondeur}€
                                            <div className="image" onClick={() => setService("classic")}>
                                                <img src="./assets/img/switch-team.png" alt="icone"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="verticalDivider"></div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="section">
                                            Durée
                                        </div>
                                        <div className="data">
                                            {convertMinutesToHours(currentLogement?.data?.cout_duree?.duree_menage_profondeur)}
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="verticalDivider"></div>
                                    </Col>
                                    <Col lg={5}>
                                        <div className="section">
                                            Linge &nbsp;
                                            {currentLogement?.data?.laundryManagementType === "FULLY_MANAGED" &&
                                             currentLogement?.data?.caution_fee_status === "paid" &&
                                            <Tag color={`#4e8900`} style={{fontSize: "10px"}}>CAUTION OK</Tag>
                                            }
                                            {currentLogement?.data?.laundryManagementType === "FULLY_MANAGED" &&
                                             (currentLogement?.data?.caution_fee_status === "unpaid" || currentLogement?.data?.caution_fee_status === "paymentFailed") &&
                                            <Tag color={`red`} style={{fontSize: "10px"}}>CAUTION IMPAYÉE</Tag>
                                            }                                            
                                        </div>
                                        <div className="data">
                                            {currentLogement?.data?.cout_duree?.cout_kit_linge}€
                                        </div>
                                    </Col>
                                </Row>
                                <div className="kitmore">
                                    {currentLogement?.data?.statut_kit_linge &&
                                        <div className="kitLinge"><img src="./assets/img/materiel.png" alt="img"/>
                                        </div>}
                                    {currentLogement?.data?.statut_produit_menager &&
                                        <div className="kitProduitMenager"><img src="/assets/img/pdt menager.png"
                                                                                alt="img"/></div>}
                                    {(currentLogement?.data?.statut_kit_linge || currentLogement?.data?.statut_produit_menager) &&
                                        <div
                                            className="kitGlobalValue">({(currentLogement?.data?.statut_kit_linge ? 3 : 0) + (currentLogement?.data?.statut_produit_menager ? 5 : 0)}€)</div>}
                                </div>
                                {currentLogement?.data?.laundryManagementType === "FULLY_MANAGED" && currentLogement?.data?.laundryPlan === "luxury" &&
                                    <div className="diamand">
                                        <img src="../assets/img/Linge Premium.png" alt="dia"/>
                                    </div>}
                                <div className="indication-lavage">
                                    {gestionLingeChecked(currentLogement?.data.laundryManagementType)}
                                </div>
                            </div>}
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className="content">
                        <div className="kit">
                            <div className="title sans-pro-semi-bold">
                                Kit d'accueil <div
                                className="sub"> ({currentLogement?.data?.cout_duree?.cout_kit_accueil}€)</div>
                                <div className="menu">
                                    <Dropdown
                                        overlay={<Menu items={getMenuItemsKit(record)}/>}
                                        placement="bottomLeft" trigger={['click']}>
                                        <IoEllipsisVertical/>
                                    </Dropdown>
                                </div>
                            </div>
                            <br/>
                            <div className="info-supp">
                                <Row>
                                    <Col lg={4}>
                                        <div className="iconHead">
                                            <div className="icone">
                                                <img src="./assets/img/Petit déjeuner icon.png"
                                                     style={{width: "55px", marginTop: "-15px"}} alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Petit déjeuner ({petitDej})</div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="iconHead">
                                            <div className="icone">
                                                <img src="./assets/img/Entretien icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Entretien ({entretien})
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="iconHead">
                                            <div className="icone">
                                                <img src="./assets/img/Hygiène icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Hygiène ({hygiene})
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="iconHead">
                                            <div className="icone">
                                                <img src="./assets/img/Mini dej icon.png"
                                                     style={{width: "30px", marginTop: "-3px"}}
                                                     alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Petit déjeuner Mini... ({petitDejmini})
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="iconHead">
                                            <div className="icone">
                                                <img src="./assets/img/mini entretien icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Entretien Mini... ({entretienmini})
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="iconHead">
                                            <div className="icone">
                                                <img src="./assets/img/mini hygiene icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Hygiène Mini... ({hygienemini})
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="kit">
                            <div className="title sans-pro-semi-bold">
                                Équipe de ménage
                                <div className="menu">
                                    <Dropdown
                                        overlay={<Menu items={getMenuItemsEquipe()}/>}
                                        placement="bottomLeft" trigger={['click']}>
                                        <IoEllipsisVertical/>
                                    </Dropdown>
                                </div>
                            </div>
                            <br/>
                            <div className="blc-content-simple">
                                <Row gutter={24}>
                                    <Col lg={24}>
                                        <div className="contour-item colored">
                                            <Row gutter={24}>
                                                {currentLogement?.data?.assignment === null || currentLogement?.data?.assignment?.responsable === null ?
                                                    <Col lg={24}>
                                                        <div className="aidem-content">
                                                            <img src="/assets/img/user-empty.png" alt="airbnb"
                                                                 className="aidem-img"/>
                                                        </div>
                                                        <div className="aidem-label sans-pro-regular">Aucun responsable
                                                        </div>
                                                        <div className="aidem-tag sans-pro-semi-bold">Resp.</div>
                                                    </Col>
                                                    :
                                                    <Col lg={24}>
                                                        {currentLogement?.data?.assignment?.status === "Offre envoyée" &&
                                                            <div className="offre-envoyee">
                                                                <div className="texte">Offre envoyée</div>
                                                                <div className="aidem-content"><Avatar
                                                                    src={`${API_ROOT}/${currentLogement?.data?.assignment?.responsable?.avatar}`}
                                                                    className="aidem-img"/></div>
                                                            </div>}
                                                        {currentLogement?.data?.assignment?.status === "Assignation en cours" &&
                                                            <div className="assignation-en-cour">
                                                                <div className="texte">Assignation en cours</div>
                                                                <div className="aidem-content"><Avatar
                                                                    src={`${API_ROOT}/${currentLogement?.data?.assignment?.responsable?.avatar}`}
                                                                    className="aidem-img"/></div>
                                                            </div>}
                                                        {currentLogement?.data?.assignment?.status === "Offre acceptée" &&
                                                            <div style={{display: "inline-block"}}>
                                                                <div className="aidem-content"><Avatar
                                                                    src={`${API_ROOT}/${currentLogement?.data?.assignment?.responsable?.avatar}`}/>
                                                                </div>
                                                                <div
                                                                    className="aidem-label sans-pro-regular">{currentLogement?.data?.assignment?.responsable?.lastname} {currentLogement?.data?.assignment?.responsable?.firstname}</div>
                                                            </div>
                                                        }
                                                        {currentLogement?.data?.assignment?.status === "Offre refusée" &&
                                                            <div style={{display: "inline-block"}}>
                                                                <div className="aidem-content"><Avatar
                                                                    src={`${API_ROOT}/${currentLogement?.data?.responsable?.avatar}`}/>
                                                                </div>
                                                                <div
                                                                    className="aidem-label sans-pro-regular">{currentLogement?.data?.responsable?.lastname} {currentLogement?.data?.responsable?.firstname}</div>
                                                            </div>
                                                        }
                                                        <div className="aidem-tag sans-pro-semi-bold">Resp.</div>
                                                    </Col>
                                                }
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="aidMSide">
                                <Row>
                                    <Col lg={4}>
                                        <img src="./assets/img/sub am icon.png" alt="icone"
                                             style={{
                                                 width: "40px",
                                                 marginTop: "10px",
                                                 float: "right",
                                                 marginRight: "10px"
                                             }}/>
                                    </Col>
                                    <Col lg={20}>
                                        <div className="blc-content-simple">
                                            <Row gutter={24}>
                                                <Col lg={24}>
                                                    <div className="contour-item">
                                                        <Row gutter={24}>
                                                            {currentLogement?.data?.assignment === null || currentLogement?.data?.assignment?.aidemenagere === null ?
                                                                <Col lg={24}>
                                                                    <div className="aidem-content">
                                                                        <img src="/assets/img/user-empty.png"
                                                                             alt="airbnb"
                                                                             className="aidem-img"/>
                                                                    </div>
                                                                    <div className="aidem-label sans-pro-regular">Aucune
                                                                        aide-ménagère
                                                                    </div>
                                                                    <div className="aidem-tag sans-pro-semi-bold">AM
                                                                    </div>
                                                                </Col>
                                                                :
                                                                <Col lg={24}>
                                                                    {currentLogement?.data?.assignment?.status === "Offre envoyée" &&
                                                                        <div className="offre-envoyee">
                                                                            <div className="texte">Offre envoyée</div>
                                                                            <div className="aidem-content"><Avatar
                                                                                src={`${API_ROOT}/${currentLogement?.data?.assignment?.aidemenagere?.avatar}`}
                                                                                className="aidem-img"/></div>
                                                                        </div>}
                                                                    {currentLogement?.data?.assignment?.status === "Assignation en cours" &&
                                                                        <div className="assignation-en-cour">
                                                                            <div className="texte">Assignation en cours</div>
                                                                            <div className="aidem-content"><Avatar
                                                                                src={`${API_ROOT}/${currentLogement?.data?.assignment?.aidemenagere?.avatar}`}
                                                                                className="aidem-img"/></div>
                                                                        </div>}
                                                                    {currentLogement?.data?.assignment?.status === "Offre acceptée" &&
                                                                        <div style={{display: "inline-block"}}>
                                                                            <div className="aidem-content"><Avatar
                                                                                src={`${API_ROOT}/${currentLogement?.data?.assignment?.aidemenagere?.avatar}`}/>
                                                                            </div>
                                                                            <div
                                                                                className="aidem-label sans-pro-regular">{currentLogement?.data?.assignment?.aidemenagere?.lastname} {currentLogement?.data?.assignment?.aidemenagere?.firstname}</div>
                                                                        </div>
                                                                    }
                                                                    {currentLogement?.data?.assignment?.status === "Offre refusée" &&
                                                                        <div style={{display: "inline-block"}}>
                                                                            <div className="aidem-content"><Avatar
                                                                                src={`${API_ROOT}/${currentLogement?.data?.aide_menagere?.avatar}`}/>
                                                                            </div>
                                                                            <div
                                                                                className="aidem-label sans-pro-regular">{currentLogement?.data?.aide_menagere?.lastname} {currentLogement?.data?.aide_menagere?.firstname}</div>
                                                                        </div>
                                                                    }
                                                                    <div className="aidem-tag sans-pro-semi-bold">AM</div>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="kit">
                            <div className="title sans-pro-semi-bold">
                                Prestations
                                <div className="menu">
                                    <Dropdown
                                        overlay={<Menu items={getMenuItemsPrestation()}/>}
                                        placement="bottomLeft" trigger={['click']}>
                                        <IoEllipsisVertical/>
                                    </Dropdown>
                                </div>
                            </div>
                            <br/>
                            <div className="info-supp">
                                <Row>
                                    <Col lg={5}>
                                        <div className="iconHead" onClick={() => {
                                            setLogementId(record.logement_id);
                                            setStatusPrestation("Terminé");
                                            setShowModalPrestation(true)
                                        }}>
                                            <div className="icone">
                                                <img src="./assets/img/Play Icon - Modifié (1).png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div
                                            className="label">Terminé({currentLogementStats?.data?.nb_prestations_finies})
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <div className="iconHead" onClick={() => {
                                            setLogementId(record.logement_id);
                                            setStatusPrestation("En cours");
                                            setShowModalPrestation(true)
                                        }}>
                                            <div className="icone">
                                                <img src="./assets/img/Play Icon - Modifié.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">En cours
                                            ({currentLogementStats?.data?.nb_prestations_en_cours})
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <div className="iconHead" onClick={() => {
                                            setLogementId(record.logement_id);
                                            setStatusPrestation("Programmé");
                                            setShowModalPrestation(true)
                                        }}>
                                            <div className="icone">
                                                <img src="./assets/img/Play Icon - Modifié (2).png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Programé
                                            ({currentLogementStats?.data?.nb_prestations_programmees})
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <div className="iconHead" onClick={() => {
                                            setLogementId(record.logement_id);
                                            setStatusPrestation("Annulé");
                                            setShowModalPrestation(true)
                                        }}>
                                            <div className="icone">
                                                <img src="./assets/img/presta del icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="label">Annulé
                                            ({currentLogementStats?.data?.nb_prestations_annules})
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="kit">
                            <div className="title sans-pro-semi-bold">
                                Planning synchronisé
                                <div className="menu">
                                    <Dropdown
                                        overlay={<Menu items={getMenuItemsIcal()}/>}
                                        placement="bottomLeft" trigger={['click']}>
                                        <IoEllipsisVertical/>
                                    </Dropdown>
                                </div>
                            </div>
                            <br/>
                            <div className="info-ical">
                                {currentLogement?.data?.icalendarUrl !== "O" && currentLogement?.data?.icalendarUrl.map((item, index) =>
                                    <div className="element" key={"ical_element_"+index}>
                                        <div className="head">ICAL {index+1} :</div>
                                        <div className="contender"><a href={item["url"]}>Lien du calendrier</a></div>
                                        <div className="action" onClick={()=> null}><IoTrashOutline /></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <SupprimerLogementModalView
                        logementId={logementId}
                        logementIds={[]}
                        status={showModalSupprimer}
                        nbLogement={1}
                        handleShowNotPossibleModal={setShowModalImpossibleSupprimer}
                        handleCancel={() => {
                            setShowModalSupprimer(false);
                            close();
                        }}
                    />

                   <LaundryCautionFeesModal
                        key={showCautionFeesModal}
                        status={showCautionFeesModal}
                        logement={currentLogement?.data}
                        handleCancelSuccess={() => {
                            setShowCautionFeesModal(false);
                            window.location.reload();
                        }}
                        handleCancel={() => {
                            setShowCautionFeesModal(false);
                        }}                        
                    />       

                    <ImpossibleSupprimerLogementModalView
                        status={showModalImpossibleSupprimer}
                        nbLogement={1}
                        handleCancel={() => setShowModalImpossibleSupprimer(false)}
                    />
                    <ModifierForfaitView
                        status={showModalModifierForfait}
                        logement={currentLogement?.data}
                        handleCancel={() => setShowModalModifierForfait(false)}
                    />
                    <KitAccueilModalView
                        status={showModalAccueil}
                        handleCancel={() => setShowModalAccueil(false)}
                    />
                    {logementId !== null && <DemanderEquipeLogementModalView
                        logementId={logementId}
                        status={showModalDemande}
                        nbLogement={1}
                        logementIds={[logementId]}
                        handleCancel={() => setShowModalDemande(false)}
                    />}
                    {logementId !== null && <AssignerEquipeLogementModalView
                        logementId={logementId}
                        status={showModalAssigner}
                        nbLogement={1}
                        handleCancel={() => setShowModalAssigner(false)}
                    />}
                    {logementId !== null && <AssignerAideEquipeLogementModalView
                        logementId={logementId}
                        status={showModalAssignerAide}
                        nbLogement={1}
                        handleCancel={() => setShowModalAssignerAide(false)}
                    />}
                    {logementId !== null && <PlanningFilteredLogementPage
                        record={currentLogement?.data}
                        status={showModalPrestation}
                        logementId={logementId}
                        statusPrestation={statusPrestation}
                        handleCancel={() => setShowModalPrestation(false)}
                    />}
                    <InstructionLogementPage
                        record={currentLogement}
                        status={showModalInstruction}
                        handleCancel={() => setShowModalshowModalInstruction(false)}
                    />
                    <SupprimerAssignationModalView
                        logementId={logementId}
                        status={showModalSupprimerAssignation}
                        handleCancel={() => setShowModalSupprimerAssignation(false)}
                    />
                    {recorded !== null && <Drawer title="" onClose={onClose} open={open} width={500}
                                                  className="client-custom-drawer-class">
                        <ClientsDrawerPage record={recorded} closeDrawer={setOpen} setOpenLogement={setOpenLogement}
                                           setRecordLogement={setRecordLogement}/>
                    </Drawer>}

                    {showModalProgrammerService && <ProgrammerManuellementModalView
                        logementParams={currentLogement?.data}
                        status={showModalProgrammerService}
                        user={currentLogement?.data?.user}
                        handleCancel={() => setShowModalProgrammerService(false)}
                    />}
                </div>
            }</>
    );
}

export default LogementsDrawerPage;
