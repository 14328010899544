import React, {useState, useEffect} from 'react';
import "../../styles/style.scss"
import {Col, Input, Row, Select, Tabs, Form, Lab} from "antd";

const LoueursPartPage = ({checker, tarifs, onUpdate}) => {
    const [laundryQuality, setLaundryQuality] = useState('standard');
    const [serviceAppoint, setServiceAppoint] = useState("Dépôt de linge");
    const [dataUpdate, setDataUpdate] = useState({});
    const items = [
        {
            key: 'standard',
            label: <div className="sans-pro-light">Standard</div>,
            children: <div style={{paddingTop:"10px", fontSize:"11px", color:"#7F7F7F"}}>Prix forfaitaire pour la location et la blanchisserie du linge.</div>,
        },
        {
            key: 'luxury',
            label: <div className="sans-pro-light">Luxe</div>,
            children: <div style={{paddingTop:"10px", fontSize:"11px", color:"#7F7F7F"}}>Prix forfaitaire pour la location et la blanchisserie du linge.</div>,
        },
    ];
    const onChange = (key) => {
        console.log(key);
        setLaundryQuality(key);
    };

    const [form] = Form.useForm();

    const handleChange = (value) => {
        setServiceAppoint(value);
    };

    useEffect(() => {
        if(tarifs) {
            form.setFieldsValue({
                "keys_deposit": getServiceAppointPrice("keys_deposit"),
                "laundry_deposit": getServiceAppointPrice("laundry_deposit"),
                "laundry_collect":getServiceAppointPrice("laundry_collect"),
                "other": getServiceAppointPrice("other"),
                "vat_con_all": getCustomerVAT("enterprise", "ALL"),
                "vat_part_laundry":getCustomerVAT("particular", "laundry"),
                "vat_part_other": getCustomerVAT("particular", "other"),
                "plan_standard": getPlanPrice("standard"),
                "classic_laundry": tarifs?.laundry?.classicLaundryPricings?.prix,
                "deep_laundry": tarifs?.laundry?.deepLaundryPricings?.prix,
                "classic_laundry_part": tarifs?.laundry?.classicLaundryPricings?.prix_ht_part,
                "deep_laundry_part": tarifs?.laundry?.deepLaundryPricings?.prix_ht_part,
                "laundry_product":tarifs?.laundry?.laundryProduct?.prix,
                "laundry_kit": tarifs?.laundry?.cleaningKit?.prix,
                "first_kit": getLaundryLease("standard")?.firstKitFee,
                "next_kit": getLaundryLease("standard")?.nextKitFee,
                "supplementary_kit": getLaundryLease("standard")?.supplementaryFee,
                "first_kit_luxury": getLaundryLease("luxury")?.firstKitFee,
                "next_kit_luxury": getLaundryLease("luxury")?.nextKitFee,
                "supplementary_kit_luxury": getLaundryLease("luxury")?.supplementaryFee,
                "avg_lease_duration": tarifs?.laundry?.laundryDurationMajoration?.leaseDurationMajoration,
                "min_duration": tarifs?.laundry?.laundryAreaDurationOptions?.minDuration,
                "garden_duration": tarifs?.laundry?.laundryAreaDurationOptions?.externalDurationRate,
                "Lavomatic": tarifs?.laundry?.laundryDurationMajoration?.lavomaticDurationMajoration,
                "near_market": tarifs?.gestionClesFee?.duree_service,
                "deep_cleaning_duration_rate":tarifs?.laundry?.deepCleaningDurationRate.duree_service
            });
        }

    }, [tarifs, form]);

    const getServiceAppointPrice = (key)=> {
        if(tarifs) {
            const service = tarifs.supportServices.find(svc=>svc.ref === key);
            return service ? service.prix : "0.00";
        }
        return "0.00"
    }

    const getCustomerVAT = (profile, type)=> {
        if(tarifs) {
            const vat = tarifs.vat.find(vat=>vat.userType === "customer" && vat.userProfile === profile && vat.serviceType === type);
            return vat ? vat.value : "0.00";
        }
        return "0.00"
    }

    const getPlanPrice = (planName) => {
        if(tarifs) {
            const p = tarifs.subPlans.find(plan=>plan.name === planName);
            return p ? p.prices[0]?.amount : "0.00";
        }
        return "0.00"
    }

    const getLaundryLease = (quality) => {
        if(tarifs) {
            return tarifs.laundry?.laundryLeaseFees?.find(elt=>elt.laundryQuality === quality);
        }
    }

    const updateVAT = (profile, type, value)=> {
        if(tarifs) {
            const vat = tarifs.vat.find(v=>v.userType === "customer" && v.userProfile === profile && v.serviceType === type);
            if(dataUpdate.vats) {
                const index = dataUpdate.vats.findIndex(elt=>elt.id === vat.id);
                if(index === -1) {
                    dataUpdate.vats.push({id: vat.id, value});
                } else {
                    dataUpdate.vats[index].value = value;
                }
                
            } else {
                dataUpdate.vats = [{id: vat.id, value}];
            }
            onUpdate(dataUpdate);
        }
    }

    const updatePlanPrice = (plan, value) => {
        if(tarifs) {
            if(dataUpdate.plans) {
                const index = dataUpdate.plans.findIndex(elt=>elt.ref === plan);
                if(index === -1) {
                    dataUpdate.plans.push({ref: plan, value});
                } else {
                    dataUpdate.plans[index].value = value;
                }
                
            } else {
                dataUpdate.plans = [{ref: plan, value}];
            }
            onUpdate(dataUpdate);
        }
    }

    const updateServiceAppointPrice = (key, value)=> {
        if(tarifs) {
            if(dataUpdate.supportServices) {
                const index = dataUpdate.supportServices.findIndex(elt=>elt.ref === key);
                if(index === -1) {
                    dataUpdate.supportServices.push({ref: key, field: "prix", value});
                } else {
                    dataUpdate.supportServices[index].value = value;
                }
                
            } else {
                dataUpdate.supportServices = [{ref: key, field: "reverse", value}];
            }
            onUpdate(dataUpdate);
        }
    }

    const updateClassicLaundryPrice = (field, value) => {
        if(dataUpdate.classicLaundry) {
            dataUpdate.classicLaundry[field] = value;
        } else {
            dataUpdate.classicLaundry = {id: tarifs?.laundry?.classicLaundryPricings?.id};
            dataUpdate.classicLaundry[field] = value;
        }
        onUpdate(dataUpdate);
    }

    const updateDeepLaundryPrice = (field, value) => {
        if(dataUpdate.deepLaundry) {
            dataUpdate.deepLaundry[field] = value;
        } else {
            dataUpdate.deepLaundry = {id: tarifs?.laundry?.deepLaundryPricings?.id};
            dataUpdate.deepLaundry[field] = value;
        }
        onUpdate(dataUpdate);        
    }

    const updateLaundryProductPrice = (value) => {
        if(dataUpdate.laundryProduct) {
            dataUpdate.laundryProduct.value = value;
        } else {
            dataUpdate.laundryProduct = {id: tarifs?.laundry?.laundryProduct?.id, value}
        }
        onUpdate(dataUpdate);
    }

    const updateLaundryKitPrice = (value) => {
        if(dataUpdate.laundryKit) {
            dataUpdate.laundryKit.value = value;
        } else {
            dataUpdate.laundryKit = {id: tarifs?.cleaningKit?.id, value}
        }        
        onUpdate(dataUpdate);
    }


    const updateStandardLaundryLeaseFees = (field, value) => {
        const standardFees = getLaundryLease("standard");
        if(standardFees) {
            if(tarifs) {
                if(dataUpdate.LaundryLeaseFees) {
                    const index = dataUpdate.LaundryLeaseFees.findIndex(elt=>elt.id === standardFees.id && elt.field === field);
                    if(index === -1) {
                        dataUpdate.LaundryLeaseFees.push({id: standardFees.id, field, value});
                    } else {
                        dataUpdate.LaundryLeaseFees[index].value = value;
                    }
                    
                } else {
                    dataUpdate.LaundryLeaseFees = [{id: standardFees.id, field, value}];
                }
            }
            onUpdate(dataUpdate);
        }
    }

    const updateLuxuryLaundryLeaseFees = (field, value) => {
        const luxuryFees = getLaundryLease("luxury");
        if(luxuryFees) {
            if(tarifs) {
                if(dataUpdate.LaundryLeaseFees) {
                    const index = dataUpdate.LaundryLeaseFees.findIndex(elt=>elt.id === luxuryFees.id && elt.field === field);
                    if(index === -1) {
                        dataUpdate.LaundryLeaseFees.push({id: luxuryFees.id, field, value});
                    } else {
                        dataUpdate.LaundryLeaseFees[index].value = value;
                    }
                    
                } else {
                    dataUpdate.LaundryLeaseFees = [{id: luxuryFees.id, field, value}];
                }
            }
            onUpdate(dataUpdate);
        }
    }


    const updateLaundryDurationMajoration = (field, value) => {
        const id = tarifs?.laundry?.laundryDurationMajoration?.id;
        if(id) {
            if(tarifs) {
                if(dataUpdate.laundryDurationMajoration) {
                    const index = dataUpdate.laundryDurationMajoration.findIndex(elt=>elt.id === id && elt.field === field);
                    if(index === -1) {
                        dataUpdate.laundryDurationMajoration.push({id, field, value});
                    } else {
                        dataUpdate.laundryDurationMajoration[index].value = value;
                    }
                    
                } else {
                    dataUpdate.laundryDurationMajoration = [{id, field, value}];
                }
            }
            onUpdate(dataUpdate);
        }
    }

    const updateLaundryAreaDurationOptions = (field, value) => {
        const id = tarifs?.laundry?.laundryAreaDurationOptions?.id;
        if(id) {
            if(dataUpdate.laundryAreaDurationOptions) {
                const index = dataUpdate.laundryAreaDurationOptions.findIndex(elt=>elt.id === id && elt.field === field);
                if(index === -1) {
                    dataUpdate.laundryAreaDurationOptions.push({id, field, value});
                } else {
                    dataUpdate.laundryAreaDurationOptions[index].value = value;
                }
                
            } else {
                dataUpdate.laundryAreaDurationOptions = [{id, field, value}];
            }
            onUpdate(dataUpdate);
        }
    }

    const updateAmenetiePrice = (id, value) => {
        if(dataUpdate.amenities) {
            const index = dataUpdate.amenities.findIndex(elt=>elt.id === id);
            if(index === -1) {
                dataUpdate.amenities.push({id, value});
            } else {
                dataUpdate.amenities[index].value = value;
            }
            
        } else {
            dataUpdate.amenities = [{id, value}];
        }
        onUpdate(dataUpdate);
    }

    const updateLaundryAreaDurationRates = (id, value) => {
        if(dataUpdate.areaDurationRates) {
            const index = dataUpdate.areaDurationRates.findIndex(elt=>elt.id === id);
            if(index === -1) {
                dataUpdate.areaDurationRates.push({id, value});
            } else {
                dataUpdate.areaDurationRates[index].value = value;
            }
            
        } else {
            dataUpdate.areaDurationRates = [{id, value}];
        }
        onUpdate(dataUpdate);
    }


    const updateNearMarketFee = (value) =>{
        if(dataUpdate.gestionClesFee) {
            dataUpdate.gestionClesFee.value = value;
        } else {
            dataUpdate.gestionClesFee = {id: tarifs?.gestionClesFee?.id, value}
        }  
        onUpdate(dataUpdate);
    }

    const updateDeepCleaningDurationRate = (value) => {
        if(dataUpdate.deepCleaningDurationRate) {
            dataUpdate.deepCleaningDurationRate.value = value;
        } else {
            dataUpdate.deepCleaningDurationRate = {id: tarifs?.laundry?.deepCleaningDurationRate?.id, value};
        } 
        onUpdate(dataUpdate);
    }



    return (
        <div className="loueurs-part-page">
            
            <Form
                name="basic"
                layout="vertical"
                className="form-edit-profil"
                form={form}
                >
                    
            <div className="separator"></div>
            <Row>
                <Col lg={6}>
                    <div className="side">
                        <div className="titre">TVA</div>
                        <div className="desc">
                            Taux de TVA applicable selon le type de services
                        </div>
                    </div>
                </Col>
                <Col lg={18}>
                    <div className="sideContent">
                        <Row>
                            {/* <Col lg={8}>
                                <div className="head">
                                    {checker ? "Forfait ménage" : "Tous services"}
                                </div>
                                <div className="input">
                                            <Form.Item name="laundry_collect" >
                                                <Input id='laundry_collect'/>
                                            </Form.Item>
                                    <Input value={"0.00"}  /> <div className="indice">(%)</div>
                                </div>
                            </Col> */}
                            {!checker && (
                            <>
                             <Col lg={8}>
                                <div className="head">
                                   Tous services (€) par mois
                                </div>
                                <div className="input">
                                    <Form.Item name="vat_con_all" >
                                        <Input id='vat_con_all' onChange={e=>updateVAT("enterprise", "ALL", e.target.value)}/>
                                    </Form.Item>
                                </div>
                            </Col>
                            {/* <Col lg={4}>
                              <div className="indice" style={{textAlign: "left"}}>(€) par mois</div>
                            </Col> */}
                            </>
                          )}
                            {checker && (
                            <>
                             <Col lg={8}>
                                <div className="head">
                                   Forfait ménage (€) par mois
                                </div>
                                <div className="input">
                                    <Form.Item name="vat_part_laundry" >
                                        <Input id='vat_part_laundry'  onChange={e=>updateVAT("particular", "laundry", e.target.value)}/>
                                    </Form.Item>
                                </div>
                            </Col>
                            {/* <Col lg={4}>
                              <div className="indice">(€) par mois</div>
                            </Col> */}
                            </>
                          )}
                            {checker && (
                            <>
                             <Col lg={8}>
                                <div className="head">
                                   Autre services (€) par mois
                                </div>
                                <div className="input">
                                    <Form.Item name="vat_part_other" >
                                        <Input id='vat_part_other'  onChange={e=>updateVAT("particular", "other", e.target.value)}/>
                                    </Form.Item>
                                </div>
                            </Col>
                            {/* <Col lg={4}>
                              <div className="indice">(€) par mois</div>
                            </Col> */}
                            </>
                          )}
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col lg={6}>
                    <div className="side">
                        <div className="titre">Abonnements de base</div>
                        <div className="desc">
                            Abonnements obligatoires pour l’utilisation de la plateforme Kliner
                        </div>
                    </div>
                </Col>
                <Col lg={18}>
                    <div className="sideContent">
                        <Row>
                            <Col lg={8}>
                                <div className="head">
                                    Standard (€) par logement actif 
                                </div>
                                <div className="input">
                                    <Form.Item name="plan_standard" >
                                        <Input id='plan_standard' onChange={e=>updatePlanPrice("standard",e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice-double">(€) par logement actif <br/> / mois</div> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {/* <div className="separator"></div>
            <Row>
                <Col lg={6}>
                    <div className="side">
                        <div className="titre">Abonnements optionnels</div>
                        <div className="desc">
                            Forfait payé pour le contrôle qualité sur le terrain
                        </div>
                    </div>
                </Col>
                <Col lg={18}>
                    <div className="sideContent">
                        <Row>
                            <Col lg={8}>
                                <div className="head">
                                    Pack Mini
                                </div>
                                <div className="input">
                                    <Input value={"0.00"}  />  <div className="indice-double">(€) par mois <br/>/ logement actif</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row> */}
            <div className="separator"></div>
            <Row>
                <Col lg={6}>
                    <div className="side">
                        <div className="titre">Prestations</div>
                        <div className="desc">
                            Coût des prestations de services réalisées dans les logements
                        </div>
                    </div>
                </Col>
                <Col lg={18}>
                    <div className="sideContent">
                        {
                            !checker && (
                                <Row>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage classique (€) par heure
                                    </div>
                                    <div className="input">
                                        <Form.Item name="classic_laundry" >
                                            <Input id='classic_laundry' onChange={e=>updateClassicLaundryPrice("prix",e.target.value)}/>
                                        </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage en profondeur (€) par heure
                                    </div>
                                    <div className="input">
                                        <Form.Item name="deep_laundry" >
                                            <Input id='deep_laundry'  onChange={e=>updateDeepLaundryPrice("prix", e.target.value)}/>
                                        </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                            </Row>                                
                        )

                        }
                        {
                            checker && (
                                <Row>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage classique (€) par heure
                                    </div>
                                    <div className="input">
                                        <Form.Item name="classic_laundry_part" >
                                            <Input id='classic_laundry_part' onChange={e=>updateClassicLaundryPrice("prix_ht_part", e.target.value)}/>
                                        </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage en profondeur (€) par heure
                                    </div>
                                    <div className="input">
                                        <Form.Item name="deep_laundry_part" >
                                            <Input id='deep_laundry_part'  onChange={e=>updateDeepLaundryPrice("prix_ht_part", e.target.value)}/>
                                        </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                            </Row>                                
                            )
                        }
                        <div className="separator"></div>
                        <Row>
                            <Col lg={8}>
                                <div className="subhead-colored">
                                    OPTIONS
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={11}>
                                <div className="head-content">
                                    <div className="point"></div>
                                    <div className="text">Produits ménagers (€) par service</div>
                                </div>
                                <div className="input-inline">
                                    <Form.Item name="laundry_product" >
                                        <Input id='laundry_product'  onChange={e=>updateLaundryProductPrice(e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par service</div> */}
                                </div>
                            </Col>
                            <Col lg={1} />
                            <Col lg={11}>
                                <div className="head-content">
                                    <div className="point"></div>
                                    <div className="text">Matériel de nettoyage (€) par service</div>
                                </div>
                                <div className="input-inline">
                                    <Form.Item name="laundry_kit" >
                                        <Input id='laundry_kit'  onChange={e=>updateLaundryKitPrice(e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par service</div> */}
                                </div>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row>
                            <Col lg={8}>
                                <div className="head">
                                    Service d'appoint
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Pose d’une boîte à clés</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                        <Form.Item name="keys_deposit" >
                                            <Input id='keys_deposit' onChange={e=>updateServiceAppointPrice("keys_deposit", e.target.value)}/>
                                        </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                    {checker ? <div className="indice-double">(€) par service <br/> + coût matériel</div> : <div className="indice">(€) par service</div>}
                                    </Col>
                                    
                                </Row>
                            </Col>
                            <Col lg={1} />
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Dépôt de linge</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                            <Form.Item name="laundry_deposit" >
                                                <Input id='laundry_deposit' onChange={e=>updateServiceAppointPrice("laundry_deposit", e.target.value)}/>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="indice-double">(€) par service <br/> + coût location</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Collecte de linge</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                        <Form.Item name="laundry_collect" >
                                                <Input id='laundry_collect' onChange={e=>updateServiceAppointPrice("laundry_collect", e.target.value)}/>
                                            </Form.Item>
                                        
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                    <div className="indice">(€) par service</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Autre besoin (forfait 1h)</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                            <Form.Item name="other" >
                                                <Input id='other' onChange={e=>updateServiceAppointPrice("other", e.target.value)}/>
                                            </Form.Item>
                                            
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                    <div className="indice">(€) par service</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                        </Row>
                        <br/>
                        {/* <Row>
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Contrôle Qualité</div>
                                        </div>
                                    </Col>
                                    <Col lg={13}>
                                        <div className="input-inline">
                                            <Input value={"0.00"}  /> <div className="indice-double">(€) par service <br/>au-delà du forfait</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col lg={6}>
                    <div className="side">
                        <div className="titre">Location de linge</div>
                        <div className="desc">
                            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                        </div>
                    </div>
                </Col>
                <Col lg={18}>
                    <div className="sideContent">
                      { laundryQuality === 'standard' &&
                        (<Row>
                            <Col lg={8}>
                                <div className="head">
                                    1er kit (€) par rotation
                                </div>
                                <div className="input">
                                <Form.Item name="first_kit" >
                                        <Input id='first_kit' onChange={e=>updateStandardLaundryLeaseFees("firstKitFee", e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par rotation</div> */}
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="head">
                                    Kit suivant (€) par rotation
                                </div>
                                <div className="input">
                                <Form.Item name="next_kit" >
                                        <Input id='next_kit'  onChange={e=>updateStandardLaundryLeaseFees("nextKitFee", e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par rotation</div> */}
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="head">
                                    Supplément taie (€) par rotation
                                </div>
                                <div className="input">
                                <Form.Item name="supplementary_kit" >
                                        <Input id='supplementary_kit' onChange={e=>updateStandardLaundryLeaseFees("supplementaryFee", e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice-double">(€) le jeu de deux <br/> / rotation</div> */}
                                </div>
                            </Col>
                        </Row>)
                      }
                      { laundryQuality === 'luxury' &&
                        (<Row>
                            <Col lg={8}>
                                <div className="head">
                                    1er kit (€) par rotation
                                </div>
                                <div className="input">
                                <Form.Item name="first_kit_luxury" >
                                        <Input id='first_kit_luxury'  onChange={e=>updateLuxuryLaundryLeaseFees("firstKitFee", e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par rotation</div> */}
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="head">
                                    Kit suivant (€) par rotation
                                </div>
                                <div className="input">
                                <Form.Item name="next_kit_luxury" >
                                        <Input id='next_kit_luxury'  onChange={e=>updateLuxuryLaundryLeaseFees("nextKitFee", e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par rotation</div> */}
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="head">
                                    Supplément taie (€) par rotation
                                </div>
                                <div className="input">
                                <Form.Item name="supplementary_kit_luxury" >
                                        <Input id='supplementary_kit_luxury' onChange={e=>updateLuxuryLaundryLeaseFees("supplementaryFee", e.target.value)}/>
                                    </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice-double">(€) le jeu de deux <br/> / rotation</div> */}
                                </div>
                            </Col>
                        </Row>)
                      }                      
                        <br/>
                        <Row>
                            <Col lg={24}>
                                <div className="bigContent">
                                    <Row>
                                        <Col lg={20}>
                                            <div className="text">
                                                Majoration pour location longue durée
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="input">
                                            <Form.Item name="avg_lease_duration" >
                                              <Input id='avg_lease_duration' onChange={e=>updateLaundryDurationMajoration("leaseDurationMajoration", e.target.value)}/>
                                             </Form.Item>
                                                {/* <Input value={"0.00"}  /> <div className="indice">(%)</div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col lg={6}>
                    <div className="side">
                        <div className="titre">Amenities</div>
                        <div className="desc">
                            Kits d’accueil déposés dans les logements
                        </div>
                    </div>
                </Col>
                <Col lg={18}>
                    <div className="sideContent">
                        <Row>
                            {
                                tarifs && tarifs.amenitiesFees && tarifs.amenitiesFees.map(amnetie=>(
                                    <Col lg={8}>
                                        <div className="head">
                                            {amnetie.produit} (€ par kit)
                                        </div>
                                        <div className="input">
                                            <Form.Item name={amnetie.id} initialValue={amnetie.prix}>
                                                <Input id={amnetie.id} onChange={e=>updateAmenetiePrice(amnetie.id, e.target.value)}/>
                                            </Form.Item>
                                            {/* <Input value={"0.00"}  /> <div className="indice">(€) le kit</div> */}
                                        </div>
                                </Col>
                                ))
                            }
                            {/* <Col lg={8}>
                                <div className="head">
                                    Petit déjeuner
                                </div>
                                <div className="input">
                                    <Input value={"0.00"}  /> <div className="indice">(€) le kit</div>
                                </div>
                            </Col> */}
                            {/* <Col lg={8}>
                                <div className="head">
                                    Hygiène
                                </div>
                                <div className="input">
                                    <Input value={"0.00"}  /> <div className="indice">(€) le kit</div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="head">
                                    Entretien
                                </div>
                                <div className="input">
                                    <Input value={"0.00"}  /> <div className="indice">(€) le kit</div>
                                </div>
                            </Col> */}
                        </Row>
                        <br/>
                        {/* <Row>
                            <Col lg={8}>
                                <div className="input">
                                    <Input value={"0.00"}  /> <div className="indice">(€) le Mini kit</div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="input">
                                    <Input value={"0.00"}  /> <div className="indice">(€) le Mini kit</div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="input">
                                    <Input value={"0.00"}  /> <div className="indice">(€) le Mini kit</div>
                                </div>
                            </Col>
                        </Row> */}
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col lg={6}>
                    <div className="side">
                        <div className="titre">Durée des prestations</div>
                        <div className="desc">
                            Calcul de la durée forfaitaire des prestations de ménage
                        </div>
                    </div>
                </Col>
                <Col lg={18}>
                    <div className="sideContent">
                        <Row>
                            <Col lg={8}>
                                <div className="head">
                                    Durée minimum (mins)
                                </div>
                                <div className="input">
                                <Form.Item name="min_duration">
                                    <Input id="min_duration" onChange={e=>updateLaundryAreaDurationOptions("minDuration", e.target.value)}/>
                                </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">minutes</div> */}
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="head">
                                    Métrage (jardins & terrasses) Min / m2
                                </div>
                                <div className="input">
                                <Form.Item name="garden_duration">
                                    <Input id="garden_duration" onChange={e=>updateLaundryAreaDurationOptions("externalDurationRate", e.target.value)}/>
                                </Form.Item>                                    
                                    {/* <Input value={"0.00"}  /> <div className="indice">Min / m2</div> */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <div className="head">
                                    Métrage (principal)
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                tarifs && tarifs.laundry?.laundryAreaDurationRates?.map(elt=>(
                                    <Col lg={4}>
                                    
                                        {/* <Col lg={11}>
                                            <div className="head-content">
                                                <div className="pointBlue"></div>
                                                <div className="text">{elt.description}</div>
                                            </div>
                                        </Col> */}
                                        
                                            <div className="input-inline">
                                            <label>{elt.description}</label>
                                            <Form.Item name={elt.id} initialValue={elt.durationRate}>
                                                <Input id={'areaDuration_'+elt.id} onChange={e=>updateLaundryAreaDurationRates(elt.id, e.target.value)}/>
                                            </Form.Item> 
                                                {/* <Input value={"0.00"}  />  <div className="indice">Min/m2</div> */}
                                            </div>
                                        
                                    
                                </Col>
                                ))
                            }
                            {/* <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">1 à 20m2</div>
                                        </div>
                                    </Col>
                                    <Col lg={13}>
                                        <div className="input-inline">
                                        <Form.Item name="garden_duration">
                                            <Input id="garden_duration"/>
                                        </Form.Item> 
                                            <Input value={"0.00"}  />  <div className="indice">Min/m2</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={1} />
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">21 à 30m2</div>
                                        </div>
                                    </Col>
                                    <Col lg={13}>
                                        <div className="input-inline">
                                            <Input value={"0.00"}  />  <div className="indice">Min/m2</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">31 à 70m2</div>
                                        </div>
                                    </Col>
                                    <Col lg={13}>
                                        <div className="input-inline">
                                            <Input value={"0.00"}  />  <div className="indice">Min/m2</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                            <Col lg={11}>
                                <Row>
                                <Col lg={11}>
                                    <div className="head-content">
                                        <div className="pointBlue"></div>
                                        <div className="text">71 à 100m2</div>
                                    </div>
                                </Col>
                                <Col lg={13}>
                                    <div className="input-inline">
                                        <Input value={"0.00"}  />  <div className="indice">Min/m2</div>
                                    </div>
                                </Col>
                            </Row>
                            </Col>
                            <Col lg={1} />
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Au delà de 100m2</div>
                                        </div>
                                    </Col>
                                    <Col lg={13}>
                                        <div className="input-inline">
                                            <Input value={"0.00"}  />  <div className="indice">Min/m2</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col> */}
                        </Row>
                        <div className="separator"></div>
                        <Row>
                            <Col lg={8}>
                                <div className="subhead-colored">
                                    MAJORATIONS
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={11}>
                                <div className="head-content">
                                    <div className="point"></div>
                                    <div className="text">Lavomatic (minutes)</div>
                                </div>
                                <div className="input-inline">
                                            <Form.Item name="Lavomatic">
                                                <Input id="Lavomatic" onChange={e=>updateLaundryDurationMajoration("lavomaticDurationMajoration", e.target.value)}/>
                                            </Form.Item> 
                                    {/* <Input value={"0.00"}  /> <div className="indice">minimum</div> */}
                                </div>
                            </Col>
                            <Col lg={1} />
                            <Col lg={11}>
                                <div className="head-content">
                                    <div className="point"></div>
                                    <div className="text">Commerce de proximité (minutes)</div>
                                </div>
                                <div className="input-inline">
                                          <Form.Item name="near_market">
                                                <Input id="near_market" onChange={e=>updateNearMarketFee(e.target.value)}/>
                                            </Form.Item> 
                                    {/* <Input value={"0.00"}  /> <div className="indice">minutes</div> */}
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={14}>
                                <div className="head-content">
                                    <div className="point"></div>
                                    <div className="text">Ménage en profondeur (% durée classique)</div>
                                </div>
                                <div className="input-inline">
                                <Form.Item name="deep_cleaning_duration_rate">
                                                <Input id="deep_cleaning_duration_rate"  onChange={e=>updateDeepCleaningDurationRate(e.target.value)}/>
                                            </Form.Item> 
                                    {/* <Input value={"0.00"}  /> <div className="indice">(%) durée classique</div> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            </Form>
        </div>
    );
}

export default LoueursPartPage;
