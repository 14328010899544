import React, {useState} from 'react';
import "../../styles/style.scss"
import {Col, Row, Select} from "antd";

const AvisFinalPart = ({setAvisFinalPart, setAvisFinalPartNote}) => {
    const [selected, setSelected] = useState('');
    const onChangeSelect = (value) =>{
        setAvisFinalPart({controle_manuelle_sur_photo: false, controle_video_en_direct: false});
        if(value === "oui-analyse"){
            setAvisFinalPart({controle_manuelle_sur_photo: true});
        }
        if(value === "oui-video"){
            setAvisFinalPart({controle_video_en_direct: true});
        }
    }
    return (
        <div className='AideMenagePart'>
            <Row>
                <Col lg={2}>
                    <img src="./assets/img/green-check.png" alt="icon"/>
                </Col>
                <Col lg={19}>
                    <div className="question">
                        Le contrôle de la qualité de la prestation a-t-il été effectué ?
                    </div>
                    <div className="decision">
                       <Select
                           className="controle-prestation-select"
                           placeholder="Choisir une réponse"
                           suffixIcon={<div className='icon-logement-select' style={{marginTop:'-8px'}}><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                           onChange={onChangeSelect}
                           options={[
                               {
                                   value: 'non',
                                   label: <div>Non</div>,
                               },
                               {
                                   value: 'oui-analyse',
                                   label: <div>Oui, analyse des photos fournies</div>,
                               },
                               {
                                   value: 'oui-video',
                                   label: <div>Oui, contrôle vidéo en direct</div>,
                               },
                               {
                                   value: 'oui-terrain',
                                   label: <div className="disable">Oui, contrôle sur le terrain</div>,
                               },
                           ]}
                       />
                    </div>
                </Col>
                <Col lg={3}>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col lg={2}>
                    <img src="./assets/img/green-check.png" alt="icon"/>
                </Col>
                <Col lg={19}>
                    <div className="question">
                        Comment jugez-vous la qualité de la prestation ?
                    </div>
                    <div className="decision">
                        <div className="sticker">
                            <Row>
                                <Col lg={5}><div className={"only-sticker "+(selected === "nul" ? "active" : "")} onClick={()=> {
                                    setSelected("nul");
                                    setAvisFinalPartNote({bo_stars:1});
                                }}>🥲</div></Col>
                                <Col lg={5}><div className={"only-sticker "+(selected === "peu" ? "active" : "")} onClick={()=> {
                                    setSelected("peu");
                                    setAvisFinalPartNote({bo_stars:2});
                                }}>🙁️</div></Col>
                                <Col lg={5}><div className={"only-sticker "+(selected === "moyen" ? "active" : "")} onClick={()=> {
                                    setSelected("moyen");
                                    setAvisFinalPartNote({bo_stars:3});
                                }}>😕</div></Col>
                                <Col lg={5}><div className={"only-sticker "+(selected === "bien" ? "active" : "")} onClick={()=> {
                                    setSelected("bien");
                                    setAvisFinalPartNote({bo_stars:4});
                                }}>🙂</div></Col>
                                <Col lg={4}><div className={"only-sticker "+(selected === "excelent" ? "active" : "")} onClick={()=> {
                                    setSelected("excelent");
                                    setAvisFinalPartNote({bo_stars:5});
                                }}>😍</div></Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={4}><div className="texte">Nul</div></Col>
                                <Col lg={5}><div className=""></div></Col>
                                <Col lg={5}><div className=""></div></Col>
                                <Col lg={4}><div className=""></div></Col>
                                <Col lg={5}><div className="text">Excellent</div></Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                </Col>
            </Row>
        </div>
    );
}

export default AvisFinalPart;
